<template>
  <div style="width: 100%;height: 100vh;position: relative;overflow-y: auto;background-color: white">
    <img src="../../../assets/share_top.png" style="width: 100%;height: 56px;object-fit: fill">
    <div style="text-align: center;margin-top: 30px">
      <img src="../../../assets/pay_success.png">
      <p style="margin-top: 15px;color: #333333;font-size: 18px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold ">
        报名成功！</p>
    </div>
    <div style="margin-top: 50px">
      <div class="finish" style="margin-top: 20px" @click="goHome">
        返回首页
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplySuccess",
  data() {
    return {
      timeOutHandler: undefined
    }
  },
  beforeDestroy() {
    if (this.timeOutHandler) {
      clearTimeout(this.timeOutHandler)
    }
  },
  mounted() {
    let that = this
    this.timeOutHandler = () => {
      that.goHome()
    }
    setTimeout(this.timeOutHandler, 5000)
  },
  methods: {
    goHome() {
      this.$router.replace({
        path: '/course-list',
        query: {
          user_id: this.$route.query.user_id
        }
      })
    }
  }
}
</script>

<style scoped lang="less">

.download-app {
  //box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.05);
  margin: 0px 28px;
  cursor: pointer;
  background: #FCC52D;
  border-radius: 24px 24px 24px 24px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  line-height: 40px;
  height: 40px;
}

.finish {
  margin: 0px 28px;
  cursor: pointer;
  background: #5D5BB5;
  border-radius: 24px 24px 24px 24px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  line-height: 40px;
  height: 40px;
}

</style>
